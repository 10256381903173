<template>
    <div
        v-if="buffer.error != ''"
        class="alert alert-danger"
    >
        {{ buffer.error }}
    </div>

    <div v-if="!buffer.error">
        <year-selector
            v-model="buffer.selectedYear"
            class="responsive-mx-4"
            @year-updated="updateYear()"
        />

        <div>
            <p class="h1 text-center my-4">
                {{ buffer.company.companyName }}
            </p>
        </div>

        <div
            v-if="Object.keys(displayableData.progressBar.computedData).length == 0"
            class="card responsive-mx-4"
        >
            <p class="h4 text-center my-4 text-secondary">
                {{ __('COMMON.NO_KPIS') }}
                <loading-layer :is-loading="buffer.isLoading" />
            </p>
        </div>

        <!-- Form kpi -->

        <!-- Progress bar kpi -->
        <div
            v-else
            class="responsive-mx-4"
        >
            <span class="h6">
                {{ __('COMMON.CATEGORY_COMPARE') }} <toggle-switch v-model="buffer.selectCategorical" />
            </span>
            <div
                v-for="fac in Object.keys(displayableData.progressBar.computedData)"
                :key="fac"
                class="card d-block my-4 p-4"
            >
                <collapsable-div>
                    <template #title>
                        <span class="h4 mr-4">
                            {{ fac }}
                        </span>

                        <dropdown-list
                            v-model="buffer.selectedSpecie[fac]"
                            :range="buffer.dropdownSpecies[fac]"
                        />
                    </template>
                    <template #content>
                        <div class="top-panel">
                            <div class="responsive-mx-4 mt-4 top-panel-first_item">
                                <labeled-progressbar
                                    :current-value="displayableData.progressBar.computedData[fac][
                                        buffer.selectedSpecie[fac]
                                    ].count.toPrecision(4) * 100"

                                    :max-value="100"
                                    :min-value="0"
                                    :bg-color="displayableData.progressBar.computedData[fac][buffer.selectedSpecie[fac]].color"
                                />
                                <p class="h5">
                                    {{ __('GOVERNANCE.PERFORMANCE') }}
                                    <b>
                                        {{ displayableData.progressBar.computedData[fac][buffer.selectedSpecie[fac]].label }}
                                    </b>
                                </p>

                                <hr>

                                <div v-if="Object.keys(displayableData.progressBar.computedSectorData).length != 0">
                                    <!-- CATEGORICAL -->
                                    <div v-if="buffer.selectCategorical">
                                        <p class="h5 mt-4">
                                            {{ getCategoryName(buffer.categoryHistory[buffer.selectedYear][fac]) }} mode:
                                        </p>

                                        <labeled-progressbar
                                            :current-value="
                                                displayableData.progressBar.computedSectorData[
                                                    buffer.categoryHistory[buffer.selectedYear][fac]
                                                ][
                                                    buffer.selectedSpecie[fac]
                                                ].count.toPrecision(4) * 100
                                            "
                                            :max-value="100"
                                            :min-value="0"
                                            :bg-color="
                                                displayableData.progressBar.computedSectorData[
                                                    buffer.categoryHistory[buffer.selectedYear][fac]
                                                ][
                                                    buffer.selectedSpecie[fac]
                                                ].color
                                            "
                                        />
                                        <p class="h5">
                                            {{ __('GOVERNANCE.PERFORMANCE') }}
                                            <b>
                                                {{
                                                    displayableData.progressBar.computedSectorData[
                                                        buffer.categoryHistory[buffer.selectedYear][fac]
                                                    ][
                                                        buffer.selectedSpecie[fac]
                                                    ].label
                                                }}
                                            </b>
                                        </p>
                                    </div>

                                    <!-- GLOBAL -->
                                    <div v-else>
                                        <p class="h5 mt-4">
                                            {{ __('GOVERNANCE.SECTOR_MODE') }}
                                        </p>

                                        <labeled-progressbar
                                            :current-value="displayableData.progressBar.computedSectorData[
                                                buffer.selectedSpecie[fac]
                                            ].count.toPrecision(4) * 100"

                                            :max-value="100"
                                            :min-value="0"
                                            :bg-color="displayableData.progressBar.computedSectorData[buffer.selectedSpecie[fac]].color"
                                        />
                                        <p class="h5">
                                            {{ __('GOVERNANCE.PERFORMANCE') }}
                                            <b>
                                                {{ displayableData.progressBar.computedSectorData[buffer.selectedSpecie[fac]].label }}
                                            </b>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="top-panel-second_item">
                                <p class="h4">
                                    {{ buffer.selectCategorical ? 'My category most answered' : 'Sector most answered' }}
                                </p>
                                <table
                                    v-if="Object.keys(displayableData.formAnswers).length != 0"
                                    class="table my-4"
                                >
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col">
                                                {{ __('GOVERNANCE.QUESTION') }}
                                            </th>
                                            <th
                                                class="xs-hide"
                                                scope="col"
                                            >
                                                {{ __('GOVERNANCE.ANSWER') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody
                                        v-for="section in buffer.kpiSections"
                                        :key="section.title"
                                    >
                                        <th colspan="2">
                                            <span class="h6">
                                                <b>> {{ section.title }}</b>
                                            </span>
                                        </th>
                                        <tr
                                            v-for="kpiId in section.kpiIds"
                                            :key="kpiId"
                                        >   
                                            <!-- CATEGORICAL -->
                                            <td v-if="buffer.selectCategorical">
                                                {{ buffer.governanceKpis[kpiId].name }}
                                                <div class="xs-show">
                                                    <span class="yes-no-indicator">
                                                        {{
                                                            __(`BOOL_VALUES.GOVERNANCE.${
                                                                displayableData.formAnswers[
                                                                    buffer.categoryHistory[buffer.selectedYear][fac]
                                                                ][
                                                                    buffer.selectedSpecie[fac]
                                                                ][kpiId]
                                                            }`)
                                                        }}
                                                    </span>
                                                </div>
                                            </td>
                                            <td
                                                v-if="buffer.selectCategorical"
                                                class="xs-hide"
                                            >
                                                <span class="yes-no-indicator">
                                                    {{
                                                        __(`BOOL_VALUES.GOVERNANCE.${
                                                            displayableData.formAnswers[
                                                                buffer.categoryHistory[buffer.selectedYear][fac]
                                                            ][
                                                                buffer.selectedSpecie[fac]
                                                            ][kpiId]
                                                        }`)
                                                    }}
                                                </span>
                                            </td>

                                            <!-- GLOBAL -->
                                            <td v-if="!buffer.selectCategorical">
                                                {{ buffer.governanceKpis[kpiId].name }}
                                                <div class="xs-show">
                                                    <span class="yes-no-indicator">
                                                        {{
                                                            __(`BOOL_VALUES.GOVERNANCE.${
                                                                displayableData.formAnswers[
                                                                    buffer.selectedSpecie[fac]
                                                                ][kpiId]
                                                            }`)
                                                        }}
                                                    </span>
                                                </div>
                                            </td>
                                            <td
                                                v-if="!buffer.selectCategorical"
                                                class="xs-hide"
                                            >
                                                <span class="yes-no-indicator">
                                                    {{
                                                        __(`BOOL_VALUES.GOVERNANCE.${
                                                            displayableData.formAnswers[
                                                                buffer.selectedSpecie[fac]
                                                            ][kpiId]
                                                        }`)
                                                    }}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </template>
                </collapsable-div>
            </div>
        </div>
    </div>
</template>

<script>
import GOVERNANCE_SECTIONS from '../../db/governance/db-governance_sections.json';

import API from '../../utils/api-utils.js';
import __ from '../../utils/translator.js';
import Constants from '../../Constants.js';

import LoadingLayer from '../../components/loading_layer.vue';
import YearSelector from '../../components/year_selector.vue';
import LabeledProgressbar from '../../components/labeled-progressbar.vue';
import CollapsableDiv from '../../components/collapsable_div.vue';
import DropdownList from '../../components/dropdown_list.vue';
import ToggleSwitch from '../../components/toggle-switch.vue';

import { getKpisFromType, getSpecieName, getCategoryName } from '../../db/db-utils.js';
import { thresholdLabeler } from '../../utils/form-utils.js';

const governanceKpis = {};
getKpisFromType(Constants.KPI_GOVERNANCE_ID).forEach(kpi => {
    governanceKpis[kpi.kpiId] = kpi;
});

const kpisToFetch = Object.keys(governanceKpis);

export default {
    components: {
        LoadingLayer,
        YearSelector,
        LabeledProgressbar,
        CollapsableDiv,
        DropdownList,
        ToggleSwitch
    },

    inject: ['COMPANY', 'categoryHistory'],

    data() {
        return {
            buffer: {
                selectedYear: Constants.MIN_KPIDATE_VALUE,

                governanceKpis,
                kpisToFetch,
                numOfKpis: kpisToFetch.length,
                kpiSections: GOVERNANCE_SECTIONS,

                dropdownSpecies: {},
                selectedSpecie: {},

                company: this.COMPANY.value,

                thresholdLabels: [
                    [0, { color: '#F46A6A', label: __('GOVERNANCE.UNACCEPTABLE') }],
                    [0.21, { color: '#FA925A', label: __('GOVERNANCE.LIMITED') }],
                    [0.41, { color: '#FFBA4A', label: __('GOVERNANCE.MODERATE') }],
                    [0.61, { color: '#9ABF6D', label: __('GOVERNANCE.GOOD') }],
                    [0.81, { color: '#34C38F', label: __('GOVERNANCE.BEST') }]
                ],

                formatter: new Intl.NumberFormat(),

                selectCategorical: false,
                categoryHistory: {},

                isLoading: true,
                error: ''
            },

            displayableData: {
                progressBar: {
                    computedData: {},
                    computedSectorData: {}
                },
                formAnswers: {}
            }

            
        }
    },

    watch: {
        'buffer.selectCategorical'(newVal) {
            this.buffer.selectCategorical = newVal;

            this.displayableData.progressBar.computedSectorData = {};
            this.displayableData.formAnswers = {};

            this.fetchModes();
        }
    },

    created() {
        this.updateYear();
    },

    methods: {
        resetData() {
            this.buffer.dropdownSpecies = {};
            this.buffer.selectedSpecie = {};

            this.displayableData.progressBar.computedData = {};
            this.displayableData.progressBar.computedSectorData = {};
        },

        updateYear() {
            this.buffer.isLoading = true;
            this.resetData();

            const promises = [];

            //Fetch specific data
            promises.push(API.analytics.filter({
                    year: this.buffer.selectedYear,
                    kpiId: this.buffer.kpisToFetch
                })
                .then(response => {
                    if (response.ok) {
                        this.prepareRawData(response.data);
                    } else {
                        this.buffer.error = response.error.message;
                    }
                })
                .catch(() => this.buffer.error = 'Network error, try again later.')
            );

            promises.push(this.fetchModes());

            //Ensures category history is initialized before displaying data
            promises.push(new Promise(resolve => {
                this.categoryHistory.then(history => {
                    this.buffer.categoryHistory = history;
                    resolve();
                });
            }));

            //Done
            Promise.all(promises)
                //Once all is finished, set a default selected facility
                .finally(() => this.buffer.isLoading = false );
        },

        prepareRawData(rawData) {
            const computedData = this.displayableData.progressBar.computedData;

            const dropdownSpecies = this.buffer.dropdownSpecies;
            const selectedSpecie = this.buffer.selectedSpecie;

            rawData.forEach(kpi => {
                if (computedData[kpi.facility] == undefined) {
                    computedData[kpi.facility] = {};
                    computedData[kpi.facility][kpi.specieId] = {
                        sum: kpi.value
                    };

                    //Initialize dropdown container
                    dropdownSpecies[kpi.facility] = [];
                    selectedSpecie[kpi.facility] = kpi.specieId;

                } else if (computedData[kpi.facility][kpi.specieId] == undefined) {
                    computedData[kpi.facility][kpi.specieId] = {
                        sum: kpi.value
                    };

                } else {
                    computedData[kpi.facility][kpi.specieId].sum += kpi.value;
                }
            });

            Object.entries(computedData).forEach(([fac, specieObj]) => {
                Object.entries(specieObj).forEach(([specieId, computed]) => {
                    const percent = computed.sum / this.buffer.numOfKpis;
                    const obj = thresholdLabeler(percent, this.buffer.thresholdLabels);
                    computedData[fac][specieId] = {
                        count: percent,
                        color: obj.color,
                        label: obj.label
                    };

                    //Build species dropdown:
                    dropdownSpecies[fac].push(
                        {
                            name: getSpecieName(specieId),
                            value: specieId,
                        }
                    );
                });
            });
        },

        async fetchModes() {
            await API.analytics.mode({
                    year: this.buffer.selectedYear,
                    activityId: null, //MUST BE NULL FOR THIS KPI TYPE
                    kpiId: this.buffer.kpisToFetch,
                    categorical: this.buffer.selectCategorical
                })
                .then(response => {
                    if (response.ok) {
                        this.prepareSectorFormRawData(response.data);
                    } else {
                        this.buffer.error = response.error.message;
                    }
                })
                .catch(() => this.buffer.error = 'Network error, try again later.');
        },

        //Sector form answers
        prepareSectorFormRawData(rawData) {
            const formAnswers = this.displayableData.formAnswers;

            const aggregatedAnswers = {};

            if (this.buffer.selectCategorical) {
                // Categorical
                rawData.forEach(kpi => {
                    if (formAnswers[kpi.categoryId] == undefined) {
                        formAnswers[kpi.categoryId] = {};
                        formAnswers[kpi.categoryId][kpi.specieId] = {};
                        formAnswers[kpi.categoryId][kpi.specieId][kpi.kpiId] = kpi.mode;

                        aggregatedAnswers[kpi.categoryId] = {};
                        aggregatedAnswers[kpi.categoryId][kpi.specieId] = kpi.mode;

                    } else if (formAnswers[kpi.categoryId][kpi.specieId] == undefined) {
                        formAnswers[kpi.categoryId][kpi.specieId] = {};
                        formAnswers[kpi.categoryId][kpi.specieId][kpi.kpiId] = kpi.mode;

                        aggregatedAnswers[kpi.categoryId][kpi.specieId] = kpi.mode;

                    } else {
                        formAnswers[kpi.categoryId][kpi.specieId][kpi.kpiId] = kpi.mode;

                        aggregatedAnswers[kpi.categoryId][kpi.specieId] += kpi.mode;
                    }
                });
            } else {
                // Globals
                rawData.forEach(kpi => {
                    if (formAnswers[kpi.specieId] == undefined) {
                        formAnswers[kpi.specieId] = {};
                        formAnswers[kpi.specieId][kpi.kpiId] = kpi.mode;

                        aggregatedAnswers[kpi.specieId] = kpi.mode;
                    } else {
                        formAnswers[kpi.specieId][kpi.kpiId] = kpi.mode;

                        aggregatedAnswers[kpi.specieId] += kpi.mode;
                    }
                });
            }

            this.sectorAggregatedAnswers(aggregatedAnswers);
        },

        //Sector progress bar
        sectorAggregatedAnswers(aggregatedAnswers) {
            const computedSectorData = this.displayableData.progressBar.computedSectorData;

            if (this.buffer.selectCategorical) {
                // Categorical
                Object.entries(aggregatedAnswers).forEach(([categoryId, aggregatedObj]) => {
                    Object.entries(aggregatedObj).forEach(([specieId, sum]) => {
                        const percent = sum / this.buffer.numOfKpis;
                        const obj = thresholdLabeler(percent, this.buffer.thresholdLabels);
    
                        if (computedSectorData[categoryId] == undefined) {
                            computedSectorData[categoryId] = {};
    
                            computedSectorData[categoryId][specieId] = {
                                count: percent,
                                color: obj.color,
                                label: obj.label
                            };
                        } else {    
                            computedSectorData[categoryId][specieId] = {
                                count: percent,
                                color: obj.color,
                                label: obj.label
                            };
                        }
                    });
                });
            } else {
                // Globals
                Object.entries(aggregatedAnswers).forEach(([specieId, sum]) => {
                    const percent = sum / this.buffer.numOfKpis;
                    const obj = thresholdLabeler(percent, this.buffer.thresholdLabels);
                    
                    computedSectorData[specieId] = {
                        count: percent,
                        color: obj.color,
                        label: obj.label
                    };
                });
            }
        },

        getCategoryName,
        __ //translator
    }
}
</script>

<style scoped>
.top-panel-second_item {
    max-height: 300px;
    overflow-y: auto;
}

.yes-no-indicator {
    font-weight: bold;    
}
</style>